import { useEffect, useState } from "react";

import { Typography } from "@mui/material";

import { alertError, alertSuccess } from "../../actions/AlertActions";
import {
  getInvoiceById,
  removeSR,
  updateMaterial,
  updateSr,
} from "../../api/Api";
import { deepUpdate } from "../../utils/StateUtils";
import { properString } from "../../utils/StringUtils";
import { useMediaQuery } from "../../utils/useMediaQuery";
import MaterialModal from "../common/MaterialModal";
import MaterialForm from "../common/form/MaterialForm";
import { InvoiceData, ServiceRequestData } from "./InvoiceFormUtils";

const headings = ["PRODUCT", "DESCRIPTION", "QTY", "RATE", "AMOUNT", ""];

type InvoiceTableProps = {
  invoiceData: InvoiceData | undefined;
  setInvoiceData: React.Dispatch<React.SetStateAction<InvoiceData | undefined>>;
};

export default function InvoiceTable({
  invoiceData,
  setInvoiceData,
}: InvoiceTableProps) {
  const isMobile = useMediaQuery("(max-width: 640px)");
  const [invoiceSrs, setInvoiceSrs] = useState<ServiceRequestData[]>([]);

  const formItemUpdate = () => {
    return [
      { name: "name", label: "Name", disabled: true },
      { name: "description", label: "Description", disabled: true },
      { name: "quantity", label: "Quantity" },
      { name: "rate", label: "Rate" },
    ];
  };

  useEffect(() => {
    setInvoiceSrs(invoiceData?.srs ?? []);
  }, [invoiceData]);

  const [editItemMobile, setEditItemMobile] = useState({
    display: false,
    edit: false,
    data: {},
  });

  const handleChange = (index: number, name: string, value: any) => {
    const tempData = invoiceSrs;
    const list = [...tempData];

    if (name === "invoiceQuantity") {
      if (!isNaN(value)) {
        list[index][name] = value;

        setInvoiceSrs(list);
      }
    } else if (value.match(/^\d*\.?\d*$/)) {
      list[index][name] = value;

      setInvoiceSrs(list);
    }
  };

  const [edit, setEdit] = useState(false);

  const [materialElement, setMaterialElement] = useState<
    {
      quantity: number;
      rate: number;
      amount: number;
    }[]
  >([]);

  const updateServiceRequest = (
    srId: number,
    quantity: number,
    rate: number,
    amount: number
  ) => {
    return updateSr(srId, [
      {
        op: "replace",
        path: "/invoiceQuantity",
        value: quantity,
      },
      {
        op: "replace",
        path: "/invoiceRate",
        value: rate,
      },
      {
        op: "replace",
        path: "/invoiceAmount",
        value: amount,
      },
    ]).then(() => {
      getInvoiceById(invoiceData?.id).then((data: InvoiceData) => {
        setInvoiceData(data);
      });
    });
  };

  const updateMaterialItem = (
    materialId: number,
    quantity: number,
    rate: number,
    amount: number
  ) => {
    return updateMaterial(materialId, [
      {
        op: "replace",
        path: "/invoiceQuantity",
        value: quantity,
      },
      {
        op: "replace",
        path: "/invoiceRate",
        value: rate,
      },
      {
        op: "replace",
        path: "/invoiceAmount",
        value: amount,
      },
    ]).then(() => {
      getInvoiceById(invoiceData?.id).then((data: InvoiceData) => {
        setInvoiceData(data);
        setEdit(false);
      });
    });
  };

  return (
    <div className={"shadow rounded overflow-auto w-full"}>
      {!isMobile && tableHeader()}
      {invoiceSrs.map((row, row_index) => (
        <>{isMobile ? itemCard(row, row_index) : tableItem(row, row_index)}</>
      ))}
      {editItemMobile.display && (
        <MaterialModal
          open={editItemMobile.display ? true : false}
          setOpen={(_) => {
            setEditItemMobile((current) => {
              return {
                data: {},
                display: false,
                edit: false,
              };
            });
          }}
          label="new-user-modal"
          describedby="create-new-user"
          containerClassName="max-h-[80vh] w-2/3 overflow-y-auto"
        >
          <div className="flex material-popup">
            <div>
              <Typography variant="h6">{editItemMobile.data.title} </Typography>
              <MaterialForm
                data={editItemMobile.data}
                renderArray={formItemUpdate()}
                onChange={(update) => {
                  const { name, value } = update;
                  setEditItemMobile((current) => {
                    return {
                      ...current,
                      data: deepUpdate(name, value, editItemMobile.data),
                    };
                  });

                  setEditItemMobile((data) => deepUpdate(name, value, data));
                }}
              />
              <button
                onClick={(_) => {
                  console.log("LOG: editItemMobile.data", editItemMobile.data);
                  (editItemMobile.data.isMaterial
                    ? updateMaterialItem(
                        editItemMobile.data.id,
                        editItemMobile.data.quantity || 0,
                        editItemMobile.data.rate,
                        (editItemMobile.data.quantity || 0) *
                          (editItemMobile.data.rate || 0)
                      )
                    : updateServiceRequest(
                        editItemMobile.data.id,
                        editItemMobile.data.quantity || 0,
                        editItemMobile.data.rate,
                        (editItemMobile.data.quantity || 0) *
                          (editItemMobile.data.rate || 0)
                      )
                  )
                    .then((_) => {
                      alertSuccess("Successfully Updated Item");
                      setEditItemMobile({
                        display: false,
                        data: {},
                        edit: false,
                      });
                    })
                    .catch((_) => {
                      alertError("Error Updating Item");
                    });
                }}
                color="primary"
                className="float-right p-4 material-create"
              >
                Update
              </button>
            </div>
          </div>
        </MaterialModal>
      )}
    </div>
  );

  function itemCard(row: any, row_index: number) {
    return (
      <div
        className={
          "flex flex-col p-2 m-2 lb rounded cursor-pointer hover:bg-gray-300 bg-white kanban-cards border-gray-200 border-2"
        }
        onClick={() => onClickEditItem(row, false)}
      >
        <div className="flex items-center">
          <div className="flex flex-row justify-between w-full mb-1">
            <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
              {row.subCategory
                ? properString(row.subCategory)
                : properString(row.jobType)}
            </div>
            <div className="self-end">
              <span
                className="inline-flex items-center rounded px-2 py-0.5 text-sm font-medium"
                style={{ color: "#4B4B4B" }}
              >
                ${parseFloat(row.invoiceAmount).toFixed(2)}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-grow justify-between w-full">
          <p className="text-sm ml-0.5" style={{ color: "#0023FF" }}>
            {row.description}
          </p>

          <div className="w-16 ">
            <button
              className={`break-all w-16 items-center px-1 py-0.5 rounded text-xs font-medium 
                  ${
                    invoiceData?.status === "PAID"
                      ? "bg-newGray-700 text-newGray-800"
                      : "bg-red-500 text-red-100"
                  }`}
              onClick={() => {
                removeSR(invoiceData?.id, row.serviceRequestId).then(() => {
                  getInvoiceById(invoiceData?.id).then((data: InvoiceData) => {
                    setInvoiceData(data);
                  });
                });
              }}
              disabled={invoiceData?.status === "PAID"}
            >
              Delete
            </button>
          </div>
        </div>

        <div className="grid grid-cols-2 w-full justify-between gap-x-24">
          <div>
            {
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                Qty:
                {row.invoiceQuantity}
              </span>
            }
          </div>
          <div>
            {
              <span className="inline-flex items-center rounded px-1 py-0.5 text-xs font-medium estimateStatus">
                Rate: ${row.invoiceRate}
              </span>
            }
          </div>
        </div>
        {row.materials && (
          <div className="flex flex-col p-0.5 m-0.5 rounded cursor-pointer hover:bg-gray-300 bg-white border-gray-200 border-2">
            <p className="text-sm pt-1 pb-0.5 text-gray-700 ">Materials</p>
            <div className="grid grid-cols-1 gap-0.5">
              {row.materials &&
                row.materials.map((material: any, materialIdx: number) =>
                  materialCard(material, materialIdx)
                )}
            </div>
          </div>
        )}
      </div>
    );
  }

  function materialCard(material: any, materialIdx: number) {
    return (
      <div
        className={
          "flex flex-col p-0.5 py-1 cursor-pointer hover:bg-gray-300 bg-white"
        }
        onClick={(e) => {
          onClickEditItem(material, true);
          e.stopPropagation();
        }}
      >
        <div className="flex flex-row justify-between w-full mb-1">
          <div className="flex justify-center w-20 rounded bg-newOrange-200 text-newOrange-200 bg-opacity-20 text-xs font-normal py-0.5">
            {material.name}
          </div>
          <div className="self-end">
            <span
              className="inline-flex items-center rounded px-2 py-0.5 text-xs font-medium"
              style={{ color: "#4B4B4B" }}
            >
              ${parseFloat(material.invoiceAmount).toFixed(2)}
            </span>
          </div>
        </div>
        <p className="text-xs ml-0.5" style={{ color: "#0023FF" }}>
          {material.description}
        </p>
      </div>
    );
  }
  function onClickEditItem(row: any, isMaterial: boolean) {
    console.log("LOG: row", row);
    setEditItemMobile({
      display: true,
      edit: true,
      data: {
        isMaterial: isMaterial,
        name: isMaterial
          ? row.name
          : row.subCategory
          ? row.subCategory
          : row.jobType,
        description: row.description,
        quantity: row.invoiceQuantity,
        rate: row.invoiceRate,
        amount: row.invoiceAmount,
        id: isMaterial ? row.materialId : row.serviceRequestId,
      },
    });
  }

  function tableHeader() {
    return (
      <div>
        <div className="flex flex-row w-full p-3 px-5 items-start">
          <span className="pr-5 py-2 font-semibold text-gray-500 text-sm w-12">
            #
          </span>
          <div className={"flex flex-grow w-full grid gap-2 grid-cols-7"}>
            {headings.map((val, index) => (
              <span
                className={
                  "p-1 py-2 font-semibold text-gray-500 text-sm " +
                  (index === 1 ? "col-span-2" : "") +
                  " " +
                  (index > 1 ? "text-right" : "")
                }
              >
                {val}
              </span>
            ))}
          </div>
        </div>
        <hr />
      </div>
    );
  }

  function tableItem(row: any, row_index: number) {
    return (
      <>
        <div className={"flex flex-row w-full items-center pl-1 "}>
          <span className={"p-2 text-gray-700 text-sm break-words w-12 mr-2 "}>
            <span className="">{row_index + 1}</span>
          </span>
          <div className={"w-full grid gap-2 items-center grid-cols-7 pr-2 "}>
            <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
              {row.subCategory
                ? properString(row.subCategory)
                : properString(row.jobType)}
            </span>
            <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 whitespace-pre-line">
              {row.description}
            </span>
            {/* Qty Input */}
            <input
              className={
                "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right estimate-quantity "
              }
              disabled={invoiceData?.status === "PAID"}
              type="text"
              onChange={(e) => {
                handleChange(
                  row_index,
                  "invoiceQuantity",
                  Number(e.target.value)
                );
              }}
              onBlur={() => {
                updateServiceRequest(
                  row.serviceRequestId,
                  row.invoiceQuantity,
                  row.invoiceRate,
                  Number(row.invoiceQuantity) * Number(row.invoiceRate)
                );
              }}
              value={row.invoiceQuantity}
            />
            {/* Rate Input */}
            <input
              className={
                "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent estimate-rate "
              }
              disabled={invoiceData?.status === "PAID"}
              onChange={(e) => {
                handleChange(
                  row_index,
                  "invoiceRate",
                  e.target.value.replace("$", "")
                );
              }}
              onBlur={() => {
                updateServiceRequest(
                  row.serviceRequestId,
                  row.invoiceQuantity,
                  row.invoiceRate,
                  Number(row.invoiceQuantity) * Number(row.invoiceRate)
                );
              }}
              value={"$" + row.invoiceRate}
            />
            {/* Amount Span */}
            <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
              {`$${row.invoiceAmount !== null && row.invoiceAmount.toFixed(2)}`}
            </span>
            <button
              className={`break-all w-max items-center px-3 py-0.5 rounded text-xs font-medium 
                  ${
                    invoiceData?.status === "PAID"
                      ? "bg-newGray-700 text-newGray-800"
                      : "bg-red-500 text-red-100"
                  }`}
              onClick={() => {
                removeSR(invoiceData?.id, row.serviceRequestId).then(() => {
                  getInvoiceById(invoiceData?.id).then((data: InvoiceData) => {
                    setInvoiceData(data);
                  });
                });
              }}
              disabled={invoiceData?.status === "PAID"}
            >
              Delete
            </button>
          </div>
        </div>

        {row.materials.length > 0 && (
          <div className="w-full flex flex-col p-3 px-2 opacity-70">
            <div className="text-gray-600 font-semibold text-sm pt-1 w-full">
              Materials
            </div>
            {row.materials
              .sort((a: any, b: any) => a.materialId - b.materialId)
              .map((material: any, material_index: number) => {
                return (
                  <div className={"flex flex-row w-full items-center pl-1 "}>
                    <span className="p-2 text-gray-700 text-sm break-words w-12 mr-2">
                      {row_index + 1}.{material_index + 1}
                    </span>
                    <div
                      className={
                        "w-full grid gap-2 items-center grid-cols-7 pr-2"
                      }
                    >
                      <span className="p-1 py-2 break-all text-gray-700 text-sm  ">
                        {material.name}
                      </span>
                      <span className="p-1 py-2 break-all text-gray-700 text-sm col-span-2 ">
                        {material.description}
                      </span>
                      {/* Quantity Input */}
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right material-quantity "
                        }
                        value={
                          edit && materialElement[material.materialId]
                            ? materialElement[material.materialId]?.quantity
                            : material.invoiceQuantity ?? ""
                        }
                        onChange={(e) => {
                          const newQuantity = Number(e.target.value);
                          setEdit(true);
                          setMaterialElement((materialElement) => ({
                            ...materialElement,
                            [material.materialId]: {
                              rate: material.invoiceRate,
                              amount: material.invoiceRate * newQuantity,
                              quantity: newQuantity,
                            },
                          }));
                        }}
                        onBlur={() => {
                          updateMaterialItem(
                            material.materialId,
                            materialElement[material.materialId]?.quantity,
                            materialElement[material.materialId]?.rate,
                            materialElement[material.materialId]?.amount
                          );
                        }}
                        disabled={invoiceData?.status === "PAID"}
                      />
                      {/* Rate Input */}
                      <input
                        className={
                          "p-1 py-2 mr-3 break-all text-gray-700 text-sm text-right caret-transparent material-rate "
                        }
                        disabled={invoiceData?.status === "PAID"}
                        value={
                          edit && materialElement[material.materialId]
                            ? "$" + materialElement[material.materialId]?.rate
                            : "$" + material.invoiceRate ?? ""
                        }
                        onChange={(e) => {
                          setEdit(true);
                          // const newElement = { ...materialElement };
                          // newElement[material.materialId] = {
                          //   quantity: Number(e.target.value.replace("$", "")),
                          //   rate: Number(e.target.value.replace("$", "")),
                          //   amount:
                          //     material.invoiceRate *
                          //     Number(e.target.value.replace("$", "")),
                          // };
                          const newRate = Number(
                            e.target.value.replace("$", "")
                          );
                          setMaterialElement((materialElement) => ({
                            ...materialElement,
                            [material.materialId]: {
                              quantity:
                                materialElement[material.materialId]?.quantity,
                              rate: newRate,
                              amount:
                                newRate *
                                materialElement[material.materialId]?.quantity,
                            },
                          }));
                        }}
                        onBlur={() => {
                          updateMaterial(material.materialId, [
                            {
                              op: "replace",
                              path: "/invoiceQuantity",
                              value:
                                materialElement[material.materialId]?.quantity,
                            },
                            {
                              op: "replace",
                              path: "/invoiceRate",
                              value: materialElement[material.materialId]?.rate,
                            },
                            {
                              op: "replace",
                              path: "/invoiceAmount",
                              value:
                                materialElement[material.materialId]?.amount,
                            },
                          ]).then(() => {
                            getInvoiceById(invoiceData?.id).then(
                              (data: InvoiceData) => {
                                setInvoiceData(data);
                                setEdit(false);
                              }
                            );
                          });
                        }}
                      />
                      <span className="p-1 py-2 break-all text-gray-700 text-sm  text-right">
                        {`$${
                          material.invoiceAmount !== null &&
                          material.invoiceAmount?.toFixed(2)
                        }`}
                      </span>
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </>
    );
  }
}
